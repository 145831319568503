import { Button, Pagination, Select, Spin, Table, message } from "antd";
import TableOptions from "components/TableOptions";
import useMasterLogic from "customHooks/useMasterLogic";
import useSearchFilter from "customHooks/useSearchFilter";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getFormValues, reduxForm } from "redux-form";
import {
  exportExcel,
  generateFabricReport,
  generateImageReport,
  masterSelectors,
} from "slices/masterSlice";
import { renderMobileColumn } from "utils/renderMobileColumn";
import ImageUploadReportFilter from "./ImageUploadReportFilter";

const masterName = "imageUpload";

const ImageUploadReports = () => {
  const dispatch = useDispatch();
  const [imageFilters, setImageFilters] = useState({});

  const MainImageUploadList = useSelector(
    masterSelectors?.getImageUploadReportList
  );
  const { pageSize, totalElements, currentPage } = useSelector(
    masterSelectors?.getImageUploadReportPagination
  );

  const formValues = useSelector(getFormValues(masterName));
  const isFetchingMasterList = useSelector(
    masterSelectors?.getIsFetchingMasterList
  );

  const { tableFilters } = useSearchFilter();

  const [imageUploadList, setImageUploadList] = useState(
    useSelector(masterSelectors?.getImageUploadReportList)
  );

  useEffect(() => {
    if (MainImageUploadList?.length) {
      let newList = MainImageUploadList?.map((data) => {
        if (
          data?.fabric_stock === "Yes" &&
          data?.chalan_stock === "Yes" &&
          data?.ready_stock === "Yes" &&
          data?.image_updaloaded === "No"
        ) {
          return { ...data, color: "Red" };
        }

        if (
          data?.fabric_stock === "Yes" &&
          data?.chalan_stock === "No" &&
          data?.ready_stock === "Yes" &&
          data?.image_updaloaded === "No"
        ) {
          return { ...data, color: "Red" };
        }

        if (
          data?.fabric_stock === "Yes" &&
          data?.chalan_stock === "Yes" &&
          data?.ready_stock === "No" &&
          data?.image_updaloaded === "No"
        ) {
          return { ...data, color: "Red" };
        }

        if (
          data?.fabric_stock === "No" &&
          data?.chalan_stock === "Yes" &&
          data?.ready_stock === "Yes" &&
          data?.image_updaloaded === "No"
        ) {
          return { ...data, color: "Red" };
        }

        if (
          data?.fabric_stock === "No" &&
          data?.chalan_stock === "Yes" &&
          data?.ready_stock === "No" &&
          data?.image_updaloaded === "No"
        ) {
          return { ...data, color: "Red" };
        }

        if (
          data?.fabric_stock === "Yes" &&
          data?.chalan_stock === "No" &&
          data?.ready_stock === "No" &&
          data?.image_updaloaded === "No"
        ) {
          return { ...data, color: "Red" };
        }
        if (
          data?.fabric_stock === "No" &&
          data?.chalan_stock === "No" &&
          data?.ready_stock === "Yes" &&
          data?.image_updaloaded === "No"
        ) {
          return { ...data, color: "Red" };
        }

        if (
          data?.fabric_stock === "No" &&
          data?.chalan_stock === "No" &&
          data?.ready_stock === "No"
        ) {
          return { ...data, color: "Green" };
        }
        if (data?.image_updaloaded === "Yes") {
          return { ...data, color: "Green" };
        }
      });

      setImageUploadList(newList);
    }
    // setImageUploadList(MainImageUploadList);
  }, [MainImageUploadList]);

  const getImageUploadData = useCallback(
    (pageNum = 1, itemsPerPage = 100, filterData = null) => {
      const formData = filterData || {
        mainItem: [],
        color: [],
        subCategory: [],
      };

      dispatch(
        generateImageReport({
          masterName,
          formData,
          query: {
            page: pageNum,
            pageSize: itemsPerPage,
            ...tableFilters,
          },
        })
      );
    },
    [tableFilters, dispatch]
  );

  useEffect(() => {
    getImageUploadData(1, 100);
  }, []);

  const handlePageChange = (page, pageSize) => {
    getImageUploadData(page, pageSize, {
      mainItem: formValues?.mainItem?.map((item) => item?.label) || [],
      color: formValues?.color?.map((item) => item?.label) || [],
      subCategory: formValues?.subCategory?.map((item) => item.label) || [],
    });
  };

  const [filters, setFilters] = useState({});
  const [selectedColor, setSelectedColor] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // console.log("filters:", Object.values(filters));
    let obj = Object.values(filters);

    let prevList =
      selectedColor !== undefined && selectedColor !== ""
        ? filteredData
        : MainImageUploadList;

    let tempData = prevList?.map((data) => {
      if (
        data?.fabric_stock === "Yes" &&
        data?.chalan_stock === "Yes" &&
        data?.ready_stock === "Yes" &&
        data?.image_updaloaded === "No"
      ) {
        return { ...data, color: "Red" };
      }

      if (
        data?.fabric_stock === "Yes" &&
        data?.chalan_stock === "No" &&
        data?.ready_stock === "Yes" &&
        data?.image_updaloaded === "No"
      ) {
        return { ...data, color: "Red" };
      }

      if (
        data?.fabric_stock === "Yes" &&
        data?.chalan_stock === "Yes" &&
        data?.ready_stock === "No" &&
        data?.image_updaloaded === "No"
      ) {
        return { ...data, color: "Red" };
      }

      if (
        data?.fabric_stock === "No" &&
        data?.chalan_stock === "Yes" &&
        data?.ready_stock === "Yes" &&
        data?.image_updaloaded === "No"
      ) {
        return { ...data, color: "Red" };
      }

      if (
        data?.fabric_stock === "No" &&
        data?.chalan_stock === "Yes" &&
        data?.ready_stock === "No" &&
        data?.image_updaloaded === "No"
      ) {
        return { ...data, color: "Red" };
      }

      if (
        data?.fabric_stock === "Yes" &&
        data?.chalan_stock === "No" &&
        data?.ready_stock === "No" &&
        data?.image_updaloaded === "No"
      ) {
        return { ...data, color: "Red" };
      }
      if (
        data?.fabric_stock === "No" &&
        data?.chalan_stock === "No" &&
        data?.ready_stock === "Yes" &&
        data?.image_updaloaded === "No"
      ) {
        return { ...data, color: "Red" };
      }

      if (
        data?.fabric_stock === "No" &&
        data?.chalan_stock === "No" &&
        data?.ready_stock === "No"
      ) {
        return { ...data, color: "Green" };
      }
      if (data?.image_updaloaded === "Yes") {
        return { ...data, color: "Green" };
      }
    });

    let main_item = obj?.[0]?.["main_item"] || [];
    let color_name = obj?.[0]?.["color_name"] || [];
    let sub_category_name = obj?.[0]?.["sub_category_name"] || [];
    let fabric_stock = obj?.[0]?.["fabric_stock"] || [];
    let chalan_stock = obj?.[0]?.["chalan_stock"] || [];
    let ready_stock = obj?.[0]?.["ready_stock"] || [];
    let image_updaloaded = obj?.[0]?.["image_updaloaded"] || [];
    // let red_green = obj?.[0]?.["color"] || [];
    //console.log(supplier, main_item, color_name, sub_category_name);

    if (main_item?.length)
      tempData = tempData.filter((item) => main_item.includes(item?.main_item));
    if (color_name?.length)
      tempData = tempData.filter((item) =>
        color_name.includes(item?.color_name)
      );
    if (sub_category_name?.length)
      tempData = tempData.filter((item) =>
        sub_category_name.includes(item?.sub_category_name)
      );
    if (fabric_stock?.length)
      tempData = tempData.filter((item) =>
        fabric_stock.includes(item?.fabric_stock)
      );
    if (chalan_stock?.length)
      tempData = tempData.filter((item) =>
        chalan_stock.includes(item?.chalan_stock)
      );
    if (ready_stock?.length)
      tempData = tempData.filter((item) =>
        ready_stock.includes(item?.ready_stock)
      );
    // if (red_green?.length)
    // 	tempData = tempData.filter(item =>
    // 		red_green.includes(item?.color)
    // 	);
    if (image_updaloaded?.length)
      tempData = tempData.filter((item) =>
        image_updaloaded.includes(item?.image_updaloaded)
      );

    //	console.log("fd", tempData);

    setImageUploadList(tempData);
  }, [filters]);

  useEffect(() => {
    let tempData = [];
    if (selectedColor !== undefined && selectedColor !== "") {
      tempData = imageUploadList.filter((item) =>
        item.color.startsWith(selectedColor)
      );
      setFilteredData([...tempData]);
    }
  }, [selectedColor]);

  const subcategories = Array.from(
    new Set(imageUploadList.map((item) => item.sub_category_name))
  );

  const subCategoryFilters = subcategories.map((subcategory) => ({
    text: subcategory,
    value: subcategory,
  }));

  const mainItem = Array.from(
    new Set(imageUploadList.map((item) => item.main_item))
  );

  const mainItemFilters = mainItem.map((mainItem) => ({
    text: mainItem,
    value: mainItem,
  }));

  const colors = Array.from(
    new Set(imageUploadList.map((item) => item.color_name))
  );

  const colorFilters = colors.map((colors) => ({
    text: colors,
    value: colors,
  }));

  const imageUploaded = Array.from(
    new Set(imageUploadList.map((item) => item.image_updaloaded))
  );

  const imageUploadFilters = imageUploaded.map((imageUploaded) => ({
    text: imageUploaded,
    value: imageUploaded,
  }));

  const readyStock = Array.from(
    new Set(imageUploadList.map((item) => item.ready_stock))
  );
  const readyStockFilters = readyStock.map((readyStock) => ({
    text: readyStock,
    value: readyStock,
  }));

  const redGreen = Array.from(
    new Set(imageUploadList.map((item) => item.color))
  );
  const redGreenFilter = redGreen.map((item) => ({
    text: item,
    value: item,
  }));

  const chalanStock = Array.from(
    new Set(imageUploadList.map((item) => item.chalan_stock))
  );
  const chalanStockFilters = chalanStock.map((chalanStock) => ({
    text: chalanStock,
    value: chalanStock,
  }));

  const fabricStock = Array.from(
    new Set(imageUploadList.map((item) => item.fabric_stock))
  );
  const fabricStockFilters = fabricStock.map((fabricStock) => ({
    text: fabricStock,
    value: fabricStock,
  }));

  let filteredArray = useMemo(() => []);

  const handleColorFilter = (val) => {
    setSelectedColor(val);
    let prev =
      filteredArray?.length && val !== undefined && val !== ""
        ? filteredArray
        : MainImageUploadList;
    let newList = prev?.map((data) => {
      if (
        data?.fabric_stock === "Yes" &&
        data?.chalan_stock === "Yes" &&
        data?.ready_stock === "Yes" &&
        data?.image_updaloaded === "No"
      ) {
        return { ...data, color: "Red" };
      }

      if (
        data?.fabric_stock === "Yes" &&
        data?.chalan_stock === "No" &&
        data?.ready_stock === "Yes" &&
        data?.image_updaloaded === "No"
      ) {
        return { ...data, color: "Red" };
      }

      if (
        data?.fabric_stock === "Yes" &&
        data?.chalan_stock === "Yes" &&
        data?.ready_stock === "No" &&
        data?.image_updaloaded === "No"
      ) {
        return { ...data, color: "Red" };
      }

      if (
        data?.fabric_stock === "No" &&
        data?.chalan_stock === "Yes" &&
        data?.ready_stock === "Yes" &&
        data?.image_updaloaded === "No"
      ) {
        return { ...data, color: "Red" };
      }

      if (
        data?.fabric_stock === "No" &&
        data?.chalan_stock === "Yes" &&
        data?.ready_stock === "No" &&
        data?.image_updaloaded === "No"
      ) {
        return { ...data, color: "Red" };
      }

      if (
        data?.fabric_stock === "Yes" &&
        data?.chalan_stock === "No" &&
        data?.ready_stock === "No" &&
        data?.image_updaloaded === "No"
      ) {
        return { ...data, color: "Red" };
      }
      if (
        data?.fabric_stock === "No" &&
        data?.chalan_stock === "No" &&
        data?.ready_stock === "Yes" &&
        data?.image_updaloaded === "No"
      ) {
        return { ...data, color: "Red" };
      }

      if (
        data?.fabric_stock === "No" &&
        data?.chalan_stock === "No" &&
        data?.ready_stock === "No"
      ) {
        return { ...data, color: "Green" };
      }
      if (data?.image_updaloaded === "Yes") {
        return { ...data, color: "Green" };
      }
    });
    if (val === undefined) return setImageUploadList([...newList]);
    let filtered = newList?.filter((x) => x.color.startsWith(val));
    setImageUploadList([...filtered]);
  };

  const [exportArray, setExportArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let tempData = [];

    setTimeout(() => {
      if (selectedColor !== undefined && selectedColor !== "") {
        tempData = imageUploadList.filter((item) =>
          item.color.startsWith(selectedColor)
        );
        setExportArray([...tempData]);
      } else {
        setExportArray(imageUploadList);
      }
      // Fetch data or perform any necessary actions
    }, 2000); // Simulated 2 seconds delay for data fetching
  }, [imageUploadList, selectedColor]);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleExportCSV = async () => {
    const filterData = {
      mainItem: [],
      color: [],
      subCategory: [],
      ...imageFilters,
    };

    setIsLoading(true);
    try {
      await dispatch(exportExcel(filterData)).unwrap();
    } catch (error) {
      console.error("Export failed", error);
    } finally {
      setIsLoading(false);
    }
  };

  const headers = [
    { label: "Sub Category", key: "sub_category_name" },
    { label: "Main Item", key: "main_item" },
    { label: "Color", key: "color_name" },
    { label: "Fabric Stock", key: "fabric_stock" },
    { label: "Chalan Stock", key: "chalan_stock" },
    { label: "Ready Stock", key: "ready_stock" },
    { label: "Image Uploaded", key: "image_updaloaded" },
  ];

  const columns = [
    {
      title: "Image Uploaad",

      render: (data) => {
        const primaryText = `${data?.main_item || "-"}-${
          data?.color_name || "-"
        }-${data?.sub_category_name || "-"}`;
        const secondaryText = `${data?.fabric_stock || "-"}- ${
          data?.chalan_stock || "-"
        }-${data?.ready_stock || "-"}-${data?.image_updaloaded || "-"}`;
        return renderMobileColumn(primaryText, secondaryText);
      },
      responsive: ["xs"],
    },
    {
      title: "Main Item",
      dataIndex: "main_item",
      key: "main_item",
      responsive: ["sm"],
      render: (value) => value || "-",
      // filters: mainItemFilters,
      // onFilter: (value, record) => {
      //   if (record.main_item.startsWith(value)) {
      //     filteredArray.push(record);
      //   }
      //   return record.main_item.startsWith(value);
      // },
      // filterSearch: true,
    },
    {
      title: "Color Name",
      dataIndex: "color_name",
      key: "color_name",
      responsive: ["sm"],
      render: (value) => value || "-",
      // filters: colorFilters,
      // onFilter: (value, record) => {
      //   if (record.color_name.startsWith(value)) {
      //     filteredArray.push(record);
      //   }
      //   return record.color_name.startsWith(value);
      // },
      // filterSearch: true,
    },
    {
      title: "Subcategory",
      dataIndex: "sub_category_name",
      key: "sub_category_name",
      responsive: ["sm"],
      render: (value) => value || "-",
      // filters: subCategoryFilters,
      // onFilter: (value, record) => {
      //   if (record.sub_category_name.startsWith(value)) {
      //     filteredArray.push(record);
      //   }
      //   return record.sub_category_name.startsWith(value);
      // },
      // filterSearch: true,
    },
    {
      title: "Fabric Stock",
      dataIndex: "fabric_stock",
      key: "fabric_stock",
      responsive: ["sm"],
      render: (value) => value || "-",
      filters: fabricStockFilters,
      onFilter: (value, record) => {
        if (record.fabric_stock.startsWith(value)) {
          filteredArray.push(record);
        }
        return record.fabric_stock.startsWith(value);
      },
      filterSearch: true,
    },
    {
      title: "Chalan Stock",
      dataIndex: "chalan_stock",
      key: "chalan_stock",
      responsive: ["sm"],
      render: (value) => value || "-",
      filters: chalanStockFilters,
      onFilter: (value, record) => {
        if (record.chalan_stock.startsWith(value)) {
          filteredArray.push(record);
        }
        return record.chalan_stock.startsWith(value);
      },
      filterSearch: true,
    },
    {
      title: "Ready Stock",
      dataIndex: "ready_stock",
      key: "ready_stock",
      responsive: ["sm"],
      render: (value) => value || "-",
      filters: readyStockFilters,
      onFilter: (value, record) => {
        if (record.ready_stock.startsWith(value)) {
          filteredArray.push(record);
        }
        return record.ready_stock.startsWith(value);
      },
      filterSearch: true,
    },
    {
      title: "Image Uploaded",
      dataIndex: "image_updaloaded",
      key: "image_updaloaded",
      responsive: ["sm"],
      filters: imageUploadFilters,
      onFilter: (value, record) => {
        if (record.image_updaloaded.startsWith(value)) {
          filteredArray.push(record);
        }
        return record.image_updaloaded.startsWith(value);
      },
      filterSearch: true,
      render: (text, data, index) => {
        //const values_image = formValues?.image?.[index];
        let backgroundColor = "";
        if (
          // data?.fabric_stock === "Yes" &&
          // data?.chalan_stock === "Yes" &&
          // data?.ready_stock === "Yes" &&
          data?.image_updaloaded === "No"
        ) {
          backgroundColor = "red";
        }

        // if (
        //   data?.fabric_stock === "Yes" &&
        //   data?.chalan_stock === "No" &&
        //   data?.ready_stock === "Yes" &&
        //   data?.image_updaloaded === "No"
        // ) {
        //   backgroundColor = "red";
        // }

        // if (
        //   data?.fabric_stock === "Yes" &&
        //   data?.chalan_stock === "Yes" &&
        //   data?.ready_stock === "No" &&
        //   data?.image_updaloaded === "No"
        // ) {
        //   backgroundColor = "red";
        // }

        // if (
        //   data?.fabric_stock === "No" &&
        //   data?.chalan_stock === "Yes" &&
        //   data?.ready_stock === "Yes" &&
        //   data?.image_updaloaded === "No"
        // ) {
        //   backgroundColor = "red";
        // }

        // if (
        //   data?.fabric_stock === "No" &&
        //   data?.chalan_stock === "Yes" &&
        //   data?.ready_stock === "No" &&
        //   data?.image_updaloaded === "No"
        // ) {
        //   backgroundColor = "red";
        // }

        // if (
        //   data?.fabric_stock === "Yes" &&
        //   data?.chalan_stock === "No" &&
        //   data?.ready_stock === "No" &&
        //   data?.image_updaloaded === "No"
        // ) {
        //   backgroundColor = "red";
        // }
        // if (
        //   data?.fabric_stock === "No" &&
        //   data?.chalan_stock === "No" &&
        //   data?.ready_stock === "Yes" &&
        //   data?.image_updaloaded === "No"
        // ) {
        //   backgroundColor = "red";
        // }

        // if (
        //   data?.fabric_stock === "No" &&
        //   data?.chalan_stock === "No" &&
        //   data?.ready_stock === "No"
        // ) {
        //   backgroundColor = "green";
        // }
        if (data?.image_updaloaded === "Yes") {
          backgroundColor = "green";
        }
        return {
          props: {
            style: { background: backgroundColor },
          },
          children: <div>{text}</div>,
        };
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Image-Upload Report</title>
      </Helmet>
      <TableOptions masterName={masterName} showAdd={false} />

      <ImageUploadReportFilter
        masterName={masterName}
        onFilter={getImageUploadData}
        setImageFilters={setImageFilters}
      />

      {isLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-70 backdrop-blur-sm">
          <Spin size="large" tip="Exporting CSV..." />
        </div>
      )}
      <div
        style={{ float: "right", marginBottom: "10px", marginTop: "10px" }}
        className="flex"
      >
        <Button onClick={handlePrint} type="primary" danger>
          {" "}
          Export to PDF{" "}
        </Button>
        {/* <CSVLink
          filename={"Image Uploaded Report.csv"}
          data={exportArray}
          headers={headers}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            message.success("The file is downloading");
          }}
        > */}
        <Button onClick={handleExportCSV} type="primary" danger>
          Export to CSV
        </Button>
        {/* </CSVLink> */}
        <Select
          className="ml-3"
          showSearch
          placeholder="Select color"
          allowClear
          onChange={(e) => handleColorFilter(e)}
          options={[
            {
              value: "Red",
              label: "Red",
            },
            {
              value: "Green",
              label: "Green",
            },
          ]}
        />
      </div>

      <div ref={componentRef} style={{ width: "100%" }}>
        <Table
          dataSource={imageUploadList}
          columns={columns}
          rowKey={(imageUploadList) => imageUploadList?.id}
          pagination={false}
          // pagination={{
          //   pageSize: pageSize,
          // }}
          loading={isFetchingMasterList}
          bordered
          // onChange={handleFilterChange}
        />
      </div>
      <div className="flex justify-center mt-5">
        {imageUploadList?.length ? (
          <Pagination
            pageSize={pageSize}
            total={totalElements}
            current={currentPage}
            onChange={handlePageChange}
          />
        ) : null}
      </div>
    </>
  );
};

export default reduxForm({
  form: masterName,
  enableReinitialize: true,
})(ImageUploadReports);
