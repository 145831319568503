import { Button } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, change, getFormValues, reduxForm } from "redux-form";
import { getDropdownList, masterSelectors } from "slices/masterSlice";
import {
  ReduxFormSelectField,
  ReduxFormSelectField2,
} from "utils/ReduxFormSelectField";

function ImageUploadReportFilter({ masterName, onFilter, setImageFilters }) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const formValues = useSelector(getFormValues("imageUpload"));

  const mainItemsOptions = useSelector(
    masterSelectors?.getMainItemsDropdownList
  );
  const isFetchingDropdownList = useSelector(
    masterSelectors?.getIsFetchingDropdownList
  );
  const subCategoryOptions = useSelector(
    masterSelectors?.getSubCategoriesDropdownList
  );
  const colorsOptions = useSelector(masterSelectors?.getColorsDropdownList);

  useEffect(() => {
    dispatch(getDropdownList({ masterName: "mainitem" }));
    dispatch(getDropdownList({ masterName: "colors" }));
    dispatch(getDropdownList({ masterName: "subcategory" }));
  }, [dispatch]);

  const handleFilter = () => {
    const filterPayload = {
      mainItem: formValues?.mainItem?.map((item) => item?.label) || [],
      color: formValues?.color?.map((item) => item?.label) || [],
      subCategory: formValues?.subCategory?.map((item) => item.label) || [],
    };

    setImageFilters(filterPayload);

    onFilter(1, 100, filterPayload);
  };

  const handleResetFilter = () => {
    dispatch(change("imageUpload", "mainItem", null));
    dispatch(change("imageUpload", "color", null));
    dispatch(change("imageUpload", "subCategory", null));

    onFilter(1, 100);
  };

  return (
    <div>
      {show ? (
        <>
          <div className="flex justify-end items-center md:mr-10">
            <Button
              className="text-redBase border-redBase"
              onClick={() => setShow(false)}
            >
              Hide Filter
            </Button>
          </div>
          <div className="md:flex block md:flex-wrap">
            <div className="md:mr-10 mb-5">
              <Field
                component={ReduxFormSelectField2}
                label="Main Item"
                name="mainItem"
                options={mainItemsOptions}
                placeholder="Select Main Items"
                isMulti
              />
            </div>
            <div className="md:mr-10 mb-5">
              <Field
                component={ReduxFormSelectField2}
                label="Color"
                name="color"
                placeholder="Enter color"
                options={colorsOptions}
                isMulti
              />
            </div>
            <div className="md:mr-10 mb-5">
              <Field
                component={ReduxFormSelectField}
                label="Sub-Category"
                name="subCategory"
                options={subCategoryOptions}
                placeholder="Select Subcategories"
                isLoading={isFetchingDropdownList}
                isMulti
              />
            </div>
            <div className="md:mr-10">
              <Button
                className="mt-2 text-primaryBase border-primaryDark font-semibold"
                onClick={handleFilter}
              >
                Apply Filters
              </Button>
            </div>
            <div className="md:mr-10">
              <Button
                className="mt-2 text-primaryBase border-primaryDark font-semibold"
                onClick={handleResetFilter}
              >
                Reset Filters
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="flex justify-end items-center md:mr-10">
          <Button
            className="text-primaryBase border-primaryDark"
            onClick={() => setShow(true)}
          >
            Show Filter
          </Button>
        </div>
      )}
    </div>
  );
}

export default reduxForm({
  form: "imageUpload",
  enableReinitialize: true,
})(ImageUploadReportFilter);
